import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "navbar navbar-dark navbar-expand-lg bg-primary mb-5" }
const _hoisted_2 = { class: "container-lg" }
const _hoisted_3 = {
  class: "navbar-brand py-0",
  href: "#"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inlineSvg = _resolveComponent("inlineSvg")!
  const _component_CNavbarToggler = _resolveComponent("CNavbarToggler")!
  const _component_CNavLink = _resolveComponent("CNavLink")!
  const _component_CNavbarNav = _resolveComponent("CNavbarNav")!
  const _component_CCollapse = _resolveComponent("CCollapse")!

  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("a", _hoisted_3, [
        _createVNode(_component_inlineSvg, {
          src: require('@/assets/brand/logo-slub-symboliczny-white.svg'),
          height: "60"
        }, null, 8, ["src"])
      ]),
      _createVNode(_component_CNavbarToggler, {
        "aria-label": "Toggle navigation",
        "aria-expanded": "{visible}",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.visible = !_ctx.visible))
      }),
      _createVNode(_component_CCollapse, {
        class: "navbar-collapse",
        visible: _ctx.visible
      }, {
        default: _withCtx(() => [
          _createVNode(_component_CNavbarNav, {
            component: "nav",
            class: "ms-auto"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_CNavLink, {
                href: "https://slubsymboliczny.pl",
                target: "_blank"
              }, {
                default: _withCtx(() => [
                  _createTextVNode("SlubSymboliczny.pl")
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["visible"])
    ])
  ]))
}