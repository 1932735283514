import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import CoreuiVue from "@coreui/vue";
import filters from "@/helpers/filters";
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

const app = createApp(App);

Sentry.init({
  app,
  dsn: "https://b72c684796de48cf8744d949bb664f02@o4504479640059904.ingest.sentry.io/4504479643467776",
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracePropagationTargets: [
        "api.planerslubu.test",
        "api.staging.planerslubu.pl",
        "api.planerslubu.pl",
        /^\//,
      ],
    }),
  ],
  tracesSampleRate: 1.0,
});

app.use(store);
app.use(router);
app.use(CoreuiVue);
app.config.globalProperties.$filters = filters;
app.mount("#app");
