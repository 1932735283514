
import { defineComponent } from "vue";
import InlineSvg from "vue-inline-svg";
import { CNavbarToggler, CCollapse, CNavbarNav, CNavLink } from "@coreui/vue";

export default defineComponent({
  name: "AppHeader",
  components: { InlineSvg, CNavbarToggler, CCollapse, CNavbarNav, CNavLink },
  data() {
    return {
      visible: false,
    };
  },
});
